import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Avatar, Icon, Tooltip } from "@mui/material";
import { useStore } from "@nanostores/react";
import HelpIcon from "common/components/Icons/HelpIcon";
import {
	railExpandedStore,
	toggleRail,
} from "common/stores/NavigationRailStore";
import { user } from "common/stores/UserStore";
import Link from "next/link";
import { usePathname } from "next/navigation";
import FileChartIcon from "../Icons/FileChartIcon";
import TruckIcon from "../Icons/TruckIcon";
import styles from "./leftRail.module.css";
export interface NavItem {
	id: number;
	label: string;
	path: string;
	icon?: React.ReactElement<typeof Icon>;
}

export interface LeftRailProps {
	items?: Array<NavItem>;
	bottomItems?: Array<NavItem>;
	selected?: string;
	initialExpanded?: boolean;
	railExpanded?: boolean;
	handleExpanderClick?: () => void;
	hideBaseNavItems?: boolean;
	onNavigationClick?: () => void;
}

/**
 * A side rail navigation for optera themed pages.
 */
function LeftRail({
	items = [],
	bottomItems = [],
	hideBaseNavItems = false,
	onNavigationClick,
}: LeftRailProps) {
	const railExpanded = useStore(railExpandedStore);
	const pathname = usePathname();
	const $user = useStore(user);
	const isAdmin = $user.roles.includes("write:organization");
	const surveyAdminAccess = $user.roles.includes("survey:surveyor");
	// const surveyAdminAccess = $user.endorsements.includes("survey:surveyor"); // NOTE: not using endorsement because of quirk of Survey Co setup
	const apiKeyFeatureAccess = $user.feature_flags.includes("api-keys");
	const surveyAccess = Object.keys($user.traits).includes("survey:surveyee");
	const scmv2Access = $user.endorsements.includes(
		"supply_chain_insights:brand"
	);

	const baseNavItems: NavItem[] = [];

	if (isAdmin) {
		bottomItems = [
			{
				id: 2,
				label: "User Management",
				path: "/admin/users",
				icon: <PeopleOutlineIcon />,
			},
			{
				id: 3,
				label: "Survey User Management",
				path: "/admin/survey",
				icon: <PeopleOutlineIcon />,
			},
			...bottomItems,
		];
	}
	if (scmv2Access) {
		baseNavItems.push({
			id: 7,
			label: "Supply Chain",
			path: "/supplyChainManager",
			icon: <TruckIcon />,
		});
		//comment out for now :)
		baseNavItems.push({
			id: 9,
			label: "Reports",
			path: "/reports",
			icon: <FileChartIcon />,
		});
		bottomItems.push({
			id: 8,
			label: "Help",
			path: "/facade/auth/owl-docs?r=/docs/getting-started-with-supply-chain-manager",
			icon: <HelpIcon color="var(--oc-palette-secondary-400)" />,
		});
	}
	if (surveyAccess) {
		baseNavItems.push({
			id: 4,
			label: "Survey",
			path: "/survey/campaign-select",
			icon: <ListAltIcon />,
		});
	}
	if (surveyAdminAccess) {
		baseNavItems.push({
			id: 5,
			label: "Survey Admin",
			path: "/survey/surveyProgressDashboard",
			icon: <ListAltIcon />,
		});
	}
	if (apiKeyFeatureAccess) {
		baseNavItems.push({
			id: 6,
			label: "API Key Management",
			path: "/admin/api-keys",
			icon: <KeyIcon />,
		});
	}

	const handleNavigationChange = () => {
		if (onNavigationClick) {
			onNavigationClick();
		}
	};

	let navItems = hideBaseNavItems == true ? items : [...baseNavItems, ...items];
	let navLocation = "";
	const allNavItems = [...navItems, ...bottomItems];
	if (allNavItems.length > 0 && pathname) {
		for (let i = 0; i < allNavItems.length; i++) {
			if (pathname.includes(allNavItems[i].path)) {
				navLocation = allNavItems[i].label;
			}
		}
	}

	return (
		<nav id="leftRail-nav" className={styles.nav} aria-label="Side navigation">
			<Tooltip
				arrow={true}
				placement="right"
				title={railExpanded ? "Collapse" : "Expand"}
			>
				<button
					type="button"
					className={styles.toggle}
					onClick={toggleRail}
					aria-expanded={railExpanded ? "true" : "false"}
					aria-controls="leftRail-nav"
					aria-label="Widen or narrow left side nav"
				>
					{railExpanded ? (
						<ChevronLeftIcon style={{ fontSize: 20 }} />
					) : (
						<ChevronRightIcon style={{ fontSize: 20 }} />
					)}
				</button>
			</Tooltip>
			<div className={styles.marginTransition}>
				<div className={railExpanded ? "" : styles.avatarMarginClosed}>
					{$user.organization &&
						(railExpanded ? (
							<div className={styles.currentOrg}>
								<Avatar variant="rounded" className={styles.companyAvatar}>
									{$user.organization[0]}
								</Avatar>
								<p className={`body2 ${styles.currentOrgName}`}>
									{$user.organization}
								</p>
							</div>
						) : (
							<Tooltip
								arrow={true}
								placement="right"
								title={$user.organization}
								className={styles.headerCollapsed}
							>
								<Avatar variant="rounded" className={styles.companyAvatar}>
									{$user.organization[0]}
								</Avatar>
							</Tooltip>
						))}
				</div>
				<div>
					{railExpanded ? (
						<ul aria-label="Side navigation menu" role="menubar">
							{navItems?.map((item: NavItem, i: number) => (
								<li
									key={i}
									role="none"
									className={item.label == navLocation ? styles.selected : ""}
								>
									<Link
										href={item.path}
										className={styles.navLink}
										role="menuitem"
										aria-label={item.label}
									>
										<Avatar
											variant="square"
											sx={{
												color:
													item.label == navLocation
														? "text.primary"
														: "text.secondary",
												height: 36,
												width: 36,
												"&:hover": {
													color: "text.primary",
												},
											}}
										>
											{item.icon}
										</Avatar>

										<p
											className={`${styles.navLink} ${
												item.label == navLocation && "text-primary"
											}`}
										>
											{item.label}
										</p>
									</Link>
								</li>
							))}
						</ul>
					) : (
						<ul aria-label="Side navigation menu" role="menubar">
							{navItems?.map((item: NavItem, i: number) => (
								<li
									key={i}
									className={item.label == navLocation ? styles.selected : ""}
									role="none"
								>
									<Tooltip arrow={true} placement="right" title={item.label}>
										<Link
											href={item.path}
											role="menuitem"
											onClick={() => handleNavigationChange()}
											className={styles.navLink}
											aria-label={item.label}
										>
											<Avatar
												variant="square"
												sx={{
													color:
														item.label == navLocation
															? "text.primary"
															: "text.secondary",
													height: 36,
													width: 36,
													"&:hover": {
														color: "text.primary",
													},
												}}
											>
												{item.icon}
											</Avatar>
										</Link>
									</Tooltip>
								</li>
							))}
						</ul>
					)}
				</div>
				<div className={styles.footer}>
					{railExpanded ? (
						<ul aria-label="Side navigation lower menu" role="menubar">
							{bottomItems?.map((item: NavItem, i: number) => (
								<li
									key={i}
									className={item.label == navLocation ? styles.selected : ""}
									role="none"
								>
									<Link
										href={item.path}
										role="menuitem"
										aria-label={`Route to ${item.path}`}
										className={styles.navLink}
										onClick={() => handleNavigationChange()}
									>
										<Avatar
											variant="square"
											sx={{
												color:
													item.label == navLocation
														? "text.primary"
														: "text.secondary",
												height: 36,
												width: 36,
												"&:hover": {
													color: "text.primary",
												},
											}}
										>
											{item.icon}
										</Avatar>
										<p
											className={`${styles.navLink} ${
												item.label == navLocation && "text-primary"
											}`}
										>
											{item.label}
										</p>
									</Link>
								</li>
							))}
						</ul>
					) : (
						<ul aria-label="Side navigation lower menu" role="menubar">
							{bottomItems?.map((item: NavItem, i: number) => (
								<li
									key={i}
									className={item.label == navLocation ? styles.selected : ""}
									role="none"
								>
									<Tooltip arrow={true} placement="right" title={item.label}>
										<Link
											href={item.path}
											className={styles.navLink}
											role="menuitem"
											aria-label={`Route to ${item.path}`}
											onClick={() => handleNavigationChange()}
										>
											<Avatar
												variant="square"
												sx={{
													color:
														item.label == navLocation
															? "text.primary"
															: "text.secondary",
													height: 36,
													width: 36,
													"&:hover": {
														color: "text.primary",
													},
												}}
											>
												{item.icon}
											</Avatar>
										</Link>
									</Tooltip>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</nav>
	);
}

export default LeftRail;
